"use client";

import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { Search, X } from "lucide-react";
import { debounce } from "@mui/material";
import { Input } from "@heroui/input";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@heroui/modal";
import { Button } from "@heroui/button";
import { useRouter } from "next/navigation";

function SearchModalNavbar({ searchTerm, setSearchTerm, onSearch }) {
  const [suggestions, setSuggestions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false); // Fix: Initialize as false, not []
  const router = useRouter();

  const fetchSuggestions = useCallback(
    debounce(async (term) => {
      try {
        const response = await axios.get(
          `https://api.researchfrc.com/api/suggestions?search=${term}`
        );
        console.log("Suggestions:", response.data.data);
        setSuggestions(response.data.data || []);
      } catch (error) {
        console.error("Error fetching suggestions:", error.message);
        setSuggestions([]);
      }
    }, 300),
    []
  );

  useEffect(() => {
    if (searchTerm.length > 1) {
      fetchSuggestions(searchTerm);
    } else {
      setSuggestions([]);
    }
  }, [searchTerm, fetchSuggestions]);

  const handleOpen = () => {
    console.log("Opening modal");
    setIsModalOpen(true); // Set to true (boolean)
  };

  const handleClose = () => {
    console.log("Closing modal");
    setIsModalOpen(false); // Set to false (boolean)
  };

  const handleSearch = () => {
    if (searchTerm) {
      onSearch(searchTerm);
      setIsModalOpen(false);
    }
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    setSuggestions([]);
  };

  const formatCompanyNameForUrl = (name) => {
    return name
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, "-")
      .replace(/-+$/, "");
  };

  const handleSuggestionClick = (suggestion) => {
    const formattedName = formatCompanyNameForUrl(suggestion.companyName);
    const url = `/stocks/${suggestion.ticker}/${formattedName}`;
    router.push(url);
    setIsModalOpen(false);
  };

  return (
    <>
      <Button
        onPress={handleOpen}
        variant="light"
        size="sm"
        className="flex items-center bg-1B51CA text-white"
      >
        <Search size={16} />
        Search for companies
      </Button>

      <Modal
        isOpen={isModalOpen}
        onOpenChange={setIsModalOpen} // This expects a boolean
        placement="center"
        backdrop="opaque"
        size="xl"
      >
        <ModalContent>
          {() => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Search Companies
              </ModalHeader>
              <ModalBody>
                <Input
                  className="w-full text-base mb-4"
                  type="text"
                  placeholder="Search by company or symbol"
                  value={searchTerm}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && searchTerm) {
                      onSearch(searchTerm);
                      setIsModalOpen(false);
                    }
                  }}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  style={{ fontSize: "16px" }}
                  endContent={
                    <div className="flex items-center gap-2">
                      {searchTerm && (
                        <X
                          size={16}
                          className="cursor-pointer text-gray-500"
                          onClick={handleClearSearch}
                        />
                      )}
                      <Search
                        size={16}
                        className="cursor-pointer"
                        onClick={handleSearch}
                      />
                    </div>
                  }
                />
                {searchTerm.length > 1 &&
                  (suggestions.length > 0 ? (
                    <div className="max-h-[300px] overflow-y-auto">
                      {suggestions.map((suggestion, index) => (
                        <div
                          key={index}
                          className="p-2 hover:bg-gray-100 cursor-pointer border-b last:border-b-0"
                          onClick={() => handleSuggestionClick(suggestion)}
                        >
                          <div className="flex flex-col">
                            <strong className="text-base">
                              {suggestion.ticker}
                            </strong>
                            <span className="text-sm text-gray-600">
                              {suggestion.companyName}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p className="text-center text-gray-500">
                      No results found
                    </p>
                  ))}
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={handleClose}>
                  Cancel
                </Button>
                <Button
                  color="primary"
                  onPress={handleSearch}
                  disabled={!searchTerm}
                >
                  Search
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}

export default SearchModalNavbar;
