// SearchBoxWrapper.js
"use client";

import React, { useState } from "react";
import dynamic from "next/dynamic";
import SearchModalNavbar from "@/src/components/NavBar/SearchModalNavbar";

export default function SearchBoxWrapper() {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (term) => {
    // Handle the search action here
    // For example, redirect to a search results page
    window.location.href = `/stocks?search=${encodeURIComponent(term)}`;
  };

  return (
    <SearchModalNavbar
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      onSearch={handleSearch}
    />
  );
}
