"use client";

import React, { useState } from "react";
import Link from "next/link";
import { signOut } from "next-auth/react";
import { XIcon } from "@heroicons/react/outline";
import { Button } from "@heroui/button";
import { useRouter } from "next/navigation";

const HamburgerMenu = ({ session }) => {
  const [isOpen, setIsOpen] = useState(false);
  const router = useRouter();

  const handleCloseMenu = () => setIsOpen(false);

  // Helper function to handle navigation and close menu
  const handleNavigation = (path) => {
    setIsOpen(false); // Close the menu
    router.push(path); // Navigate to the path
  };

  return (
    <>
      <div
        className="lg:hidden cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="block w-6 h-0.5 bg-white mb-1"></span>
        <span className="block w-6 h-0.5 bg-white mb-1"></span>
        <span className="block w-6 h-0.5 bg-white"></span>
      </div>
      {isOpen && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-900 text-white flex flex-col items-center justify-center">
          <div className="absolute top-10 right-10">
            <button onClick={handleCloseMenu}>
              <XIcon className="w-8 h-8 hover:text-gray-400" />
            </button>
          </div>
          {/* Welcome message */}
          {session && (
            <div className="mb-4 text-center text-AC9576 text-2xl p-3">
              Welcome,{" "}
              <span className="text-AC9576 text-2xl font-bold">
                {session.user.name}
              </span>
            </div>
          )}
          <ul className="space-y-4 text-center">
            {!session && (
              <>
                <li className="hover:text-gray-400 text-2xl cursor-pointer">
                  <Link href="/about-us" onClick={handleCloseMenu} passHref>
                    ABOUT US
                  </Link>
                </li>
                <li className="hover:text-gray-400 text-2xl cursor-pointer">
                  <Link
                    href="/about-us/team"
                    onClick={handleCloseMenu}
                    passHref
                  >
                    OUR TEAM
                  </Link>
                </li>
                <li className="hover:text-gray-400 text-2xl cursor-pointer">
                  <Link href="/top-picks" onClick={handleCloseMenu} passHref>
                    TOP PICKS
                  </Link>
                </li>
                <li className="hover:text-gray-400 text-2xl cursor-pointer">
                  <Link href="/videos" onClick={handleCloseMenu} passHref>
                    VIDEOS
                  </Link>
                </li>
                <li className="hover:text-gray-400 text-2xl cursor-pointer">
                  <Button
                    onPress={() => {
                      handleNavigation("/sign-in");
                    }}
                    className="bg-E6E6E6 w-52 mt-10"
                  >
                    SIGN IN
                  </Button>
                </li>
                <li className="hover:text-gray-400 cursor-pointer">
                  <Button
                    onPress={() => {
                      handleNavigation("/plans");
                    }}
                    className="bg-1B51CA text-white mt-4"
                  >
                    SUBSCRIBE FOR FREE
                  </Button>
                </li>
              </>
            )}
            {session && (
              <>
                <li className="hover:text-1B51CA text-2xl cursor-pointer">
                  <Link href="/" onClick={handleCloseMenu} passHref>
                    HOME
                  </Link>
                </li>
                <li className="hover:text-gray-400 text-2xl cursor-pointer">
                  <Link href="/about-us" onClick={handleCloseMenu} passHref>
                    ABOUT US
                  </Link>
                </li>
                <li className="hover:text-gray-400 text-2xl cursor-pointer">
                  <Link href="/videos" onClick={handleCloseMenu} passHref>
                    VIDEOS
                  </Link>
                </li>
                <li className="hover:text-1B51CA text-2xl cursor-pointer">
                  <Link
                    href="/account/user-information"
                    onClick={handleCloseMenu}
                    passHref
                  >
                    MY ACCOUNT
                  </Link>
                </li>
                <li className="hover:text-1B51CA text-2xl mt-20 cursor-pointer">
                  <Button
                    key="logout"
                    color="danger"
                    className="mt-20"
                    onPress={() => {
                      handleCloseMenu();
                      signOut();
                    }}
                  >
                    SIGN OUT
                  </Button>
                </li>
              </>
            )}
          </ul>
        </div>
      )}
    </>
  );
};

export default HamburgerMenu;
